import { useSite } from '@on3/ui-lib/index';
import { useEffect, useRef } from 'react';

import styles from './ArticleBarrier.module.scss';

export const ArticleBarrier = () => {
  const { subConfig } = useSite();
  const {
    annualArticleTemplateVariantId,
    articleTemplateId,
    annualPromotionCode,
    annualOffer,
    monthlyOffer,
    monthlyArticleTemplateVariantId,
    monthlyPromotionCode,
    siteKey,
  } = subConfig || {};

  const hasInitialized = useRef(false);
  const isSoftballAmerica = siteKey === 401;

  useEffect(() => {
    if (!hasInitialized.current) {
      window.tp.push([
        'init',
        function () {
          if (isSoftballAmerica) {
            window.tp.offer.show({
              offerId: monthlyOffer,
              templateId: articleTemplateId,
              templateVariantId: monthlyArticleTemplateVariantId,
              promoCode: monthlyPromotionCode,
              displayMode: 'inline',
              containerSelector: '#monthly',
            });
          }

          window.tp.offer.show({
            offerId: annualOffer,
            templateId: articleTemplateId,
            templateVariantId: annualArticleTemplateVariantId,
            promoCode: annualPromotionCode,
            displayMode: 'inline',
            containerSelector: '#annual',
          });

          if (window.gtag) {
            window.gtag('event', 'showOffer', {
              event_category: 'Subscription',
              event_label: 'Article Barrier',
              value: 1,
              template_id: articleTemplateId,
              annual_offer_id: annualOffer,
              template_variant_id: annualArticleTemplateVariantId,
            });
          }
        },
      ]);

      hasInitialized.current = true;
    }
  }, [
    isSoftballAmerica,
    monthlyOffer,
    annualOffer,
    articleTemplateId,
    annualArticleTemplateVariantId,
    annualPromotionCode,
    monthlyArticleTemplateVariantId,
    monthlyPromotionCode,
  ]);

  useEffect(() => {
    document.body.classList.add('blocker-active');

    return () => {
      document.body.classList.remove('blocker-active');
    };
  }, []);

  return (
    <>
      <div className={styles.pianoBottom} id="pianoBottom">
        <div id="annual" />
        {isSoftballAmerica && <div id="monthly" />}
      </div>

      <div className={styles.blocker} id="blocker" />
    </>
  );
};
