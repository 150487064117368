import type {
  HeadlineResponse,
  RelatedHeadlineResponse,
  TrendingListItemResponseTrendingListResponseRelatedPagedData,
} from '@on3/api';
import type { IGenericPageProps } from '@on3/ui-lib/api/schema/custom-contracts';
import { useArticle } from '@on3/ui-lib/index';
import { useAuth } from '@on3/ui-lib/src/contexts/AuthProvider';
import { useSite } from '@on3/ui-lib/src/contexts/SiteProvider';
import { externalApi } from '@on3/ui-lib/utils/api';
import clsx from 'clsx';
import { Leader } from 'components/Ads/Leader';
import { MobileMPU } from 'components/Ads/MobileMPU';
import { MPU } from 'components/Ads/MPU';
import { Article } from 'components/Headlines/Article/Article';
import { ArticleLayout } from 'components/Layouts/ArticleLayout';
import { TributeGary } from 'components/Social/TributeGary';
import { TopHeadlines } from 'components/TopHeadlines';
import { useRouter } from 'next/router';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Cookies from 'universal-cookie';
import { articleScrollAds, initializeAds } from 'utils/ads/ads';
import { isAboveBottomOfScreen, isBelowBottomOfScreen } from 'utils/viewport';

import { ArticlesList } from '../ArticleLarge/ArticlesList';
import { RelatedArticlesList } from '../RelatedArticles/RelatedArticlesList';
import { ArticleMeta } from './ArticleMeta';
import styles from './ArticleWrapper.module.scss';

const TEAM_SITE_INFINITE_SCROLL_ARTICLES = 2;

declare global {
  interface Window {
    twttr: any;
    instgrm: any;
    COMSCORE: any;
  }
}

interface ScrollArticle extends HeadlineResponse {
  isLoaded: boolean;
}
export interface IArticleWrapperProps extends IGenericPageProps {
  article: ScrollArticle | null;
  relatedArticles: RelatedHeadlineResponse[] | null;
  newsFeed?: HeadlineResponse[];
  trendingList: TrendingListItemResponseTrendingListResponseRelatedPagedData | null;
}

const onEmbedLoad = (event: MessageEvent) => {
  const ifrs: NodeListOf<HTMLIFrameElement> = document.querySelectorAll(
    'iframe.onthree-embed:not(.loaded)',
  );

  if (ifrs.length === 0) {
    return;
  }

  for (const ifr of ifrs) {
    const { data } = event || {};

    if (data?.height && data?.location === ifr?.src) {
      ifr.setAttribute('height', data?.height);
      ifr.style.height = `${+data?.height + 20}px`;
      ifr.classList.add('loaded');
    }
  }
};

export const ArticleWrapper = ({
  article: initialArticle,
  relatedArticles,
  newsFeed,
  trendingList,
}: IArticleWrapperProps) => {
  const { setArticle } = useArticle();
  const { user } = useAuth();
  const { currentSite } = useSite();
  const isFeed = currentSite?.template === 'Feed';
  const isTeam = currentSite?.isTeam && currentSite?.template === 'Traditional';
  const [loaded, setLoaded] = useState(0);
  const [active, setActive] = useState(0);
  const router = useRouter();
  const passedTeam = currentSite?.isNational ? 'on3' : currentSite?.slug;
  const [loadedArticles, setLoadedArticles] = useState([initialArticle]);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const noAccess = initialArticle?.isPremium && !user?.has;
  const cookies = useMemo(() => {
    return new Cookies();
  }, []);

  const [readStories, setReadStories] = useState(() => {
    const savedKeys = cookies.get('readStories');

    return Array.isArray(savedKeys) ? savedKeys : [];
  });
  const filteredArticle = relatedArticles?.filter(
    (article) =>
      Array.isArray(readStories) && !readStories?.includes(article.key),
  );

  // If the site isTeam, hasMoreArticles needs to be false when TEAM_SITE_INFINITE_SCROLL_ARTICLES are loaded.
  // If the site !isTeam, hasMoreArticles when all of the filtered articles are loaded
  // if noAccess is true hasMoreArticles is false immediately

  const hasMoreArticles =
    (currentSite?.isTeam &&
      loadedArticles.length < TEAM_SITE_INFINITE_SCROLL_ARTICLES) ||
    (!currentSite?.isTeam &&
      loadedArticles.length < (filteredArticle?.length || 0) &&
      !noAccess);

  const fetchData = useCallback(async () => {
    const primaryCategoryKey = filteredArticle?.[loaded]?.primaryCategory?.key;
    const categorySlug = filteredArticle?.[loaded]?.slug;

    try {
      if (primaryCategoryKey && categorySlug) {
        const newArticle = await externalApi
          .get(
            `/content/v1/categories/${primaryCategoryKey}/headlines/${categorySlug}/`,
          )

          .then((r) => r.data);

        setLoadedArticles([...loadedArticles, newArticle]);
        setLoaded(loaded + 1);
      }
    } catch (e) {
      console.debug(e);
    }
  }, [loaded, filteredArticle, loadedArticles]);

  //if article has been loaded before
  const updateArticle = useCallback((article: ScrollArticle) => {
    if (article.fullUrl) {
      router.replace(article.fullUrl, undefined, { shallow: true });
    }

    setArticle(article);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //if article hasn't been loaded before
  const firstLoadArticle = useCallback(
    (article: ScrollArticle, number: number, totalAds: string) => {
      if (article.fullUrl) {
        router.replace(article.fullUrl, undefined, { shallow: true });
      }

      setArticle(article);

      fireScrollAds(article, number, totalAds);

      if (typeof window.twttr !== 'undefined') {
        window.twttr.widgets.load();
      }

      if (typeof window.instgrm !== 'undefined') {
        window.instgrm.Embeds.process();
      }

      setReadStories((prevKeys: number[]) => {
        if (prevKeys.includes(article?.key)) {
          return prevKeys;
        }

        const updatedKeys = [...prevKeys, article?.key];

        while (updatedKeys.length > 15) {
          updatedKeys.shift();
        }

        return updatedKeys;
      });

      try {
        window.addEventListener('message', onEmbedLoad, false);
      } catch {
        console.log('resizing iframe error');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const fireInitialAds = useCallback(
    (article: HeadlineResponse, number: any, totalAds: any) => {
      if (!user?.st?.includes('premium')) {
        //On3 Her and Softball America
        const shouldAddWomensSportsTag =
          (currentSite?.key === 400 || currentSite?.key === 401) &&
          !article?.tags?.some((tag) => tag.slug === 'womens-sports');

        let tags = article?.tags?.map((tag) => tag.slug).join(',');

        if (shouldAddWomensSportsTag && article?.tags) {
          if (tags) {
            tags += ',womens-sports';
          } else {
            tags = 'womens-sports';
          }
        }

        initializeAds({
          pageType: 'article',
          team: passedTeam,
          page: number,
          incUnits: parseInt(totalAds),
          contentID: article.key,
          authorId: article?.author?.key,
          siteType: currentSite?.type,
          template: currentSite?.template,
          categoryKey: article.primaryCategory?.key,
          siteKey: currentSite?.key,
          tags,
          user,
        });
      }
    },
    [currentSite, passedTeam, user],
  );

  const fireScrollAds = useCallback(
    (article: any, number: any, totalAds: any) => {
      articleScrollAds({
        inc: totalAds,
        template: currentSite?.template,
        userStatus: user?.st,
      });
    },
    [currentSite?.template, user?.st],
  );

  const handleScroll = useCallback(() => {
    const st = window.pageYOffset || document.documentElement.scrollTop;
    const isScrollingDown = st > lastScrollTop;
    const articles = [
      ...document.querySelectorAll('[data-article]'),
    ] as HTMLElement[];

    let index = articles.findIndex(
      (i) => i?.dataset?.articlekey === loadedArticles[active]?.key?.toString(),
    );

    if (index > -1) {
      if (isScrollingDown) {
        if (index !== articles.length) {
          index += 1;
          const article = articles.find((a, i) => i === index) as HTMLElement;

          if (article && isAboveBottomOfScreen(article)) {
            setActive(active + 1);
            const currentArticle = loadedArticles[index];

            // if article hasnt been loaded
            if (currentArticle && !currentArticle?.isLoaded) {
              firstLoadArticle(
                currentArticle,
                index,
                article.dataset.totalads || '',
              );
              currentArticle.isLoaded = true;
            } else if (currentArticle) {
              currentArticle.isLoaded = true;
              updateArticle(currentArticle);
            }
          }
        }
      } else {
        if (index > 0) {
          const article = articles.find((a, i) => i === index) as HTMLElement;
          const articleToLoad = loadedArticles[index - 1];

          if (article && isBelowBottomOfScreen(article) && articleToLoad) {
            updateArticle(articleToLoad);
            setActive(active - 1);
          }
        }
      }
    }

    setLastScrollTop(st);
  }, [active, firstLoadArticle, lastScrollTop, loadedArticles, updateArticle]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    window.addEventListener('message', onEmbedLoad, false);

    return () => {
      window.addEventListener('message', onEmbedLoad);
    };
  }, []);

  useEffect(() => {
    setReadStories((prevKeys: number[]) => {
      if (prevKeys.includes(initialArticle?.key || 0)) {
        return prevKeys;
      }

      const updatedKeys = [...prevKeys, initialArticle?.key];

      while (updatedKeys.length > 15) {
        updatedKeys.shift();
      }

      return updatedKeys;
    });
  }, [initialArticle?.key]);

  useEffect(() => {
    const week = new Date();

    week.setDate(week.getDate() + 7);

    cookies.set('readStories', JSON.stringify(readStories), {
      expires: week,
      path: '/',
    });
  }, [cookies, readStories]);

  useEffect(() => {
    !noAccess &&
      initialArticle &&
      fireInitialAds(
        initialArticle,
        0,
        (
          [
            ...document.querySelectorAll('[data-article]'),
          ]?.shift() as HTMLElement
        )?.dataset.totalads || 0,
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ArticleLayout>
      <ArticleMeta />
      <div
        className={clsx(styles.articleWrapper, {
          [styles.noAccess]: noAccess,
        })}
      >
        <InfiniteScroll
          dataLength={
            currentSite?.isTeam
              ? TEAM_SITE_INFINITE_SCROLL_ARTICLES
              : loadedArticles.length
          }
          hasMore={hasMoreArticles}
          loader={<h4>Loading...</h4>} //TODO: replace with on3 spinner
          next={fetchData}
          scrollThreshold={currentSite?.isTeam ? 0.2 : `600px`}
        >
          {loadedArticles.map((item, index) => {
            const isLastArticle =
              loadedArticles[loadedArticles.length - 1]?.key === item?.key;

            return (
              <Fragment key={item?.key}>
                {item && (
                  <Article
                    {...item}
                    articleKey={item?.key}
                    articleNumber={index}
                    lastArticle={isLastArticle}
                    loaded={true}
                    trendingList={trendingList}
                  />
                )}
                <MobileMPU id={`mobile_banner_bottom-${index + 1}`} incontent />
                <Leader id={`leader_bottom-${index + 1}`} />
              </Fragment>
            );
          })}
        </InfiniteScroll>

        {isFeed && newsFeed && (
          <section>
            <ArticlesList list={newsFeed?.slice(2, 10)} />
          </section>
        )}
        {isTeam && filteredArticle && !noAccess && (
          <section>
            <RelatedArticlesList list={filteredArticle} />
          </section>
        )}
      </div>
      {!noAccess && (
        <aside className={styles.sidebar}>
          <div className={styles.stickycontainer}>
            <MPU id="mpu_top" />
            {currentSite?.key === 79 && (
              <div>
                <TributeGary />
              </div>
            )}
            {trendingList && (
              <div className={styles.trendingWrapper}>
                <TopHeadlines data={trendingList} />
              </div>
            )}
            <MPU id="mpu_middle" />
          </div>
        </aside>
      )}
    </ArticleLayout>
  );
};
